'use client'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslations } from 'next-intl'
import Head from 'next/head'

import { useForm } from 'react-hook-form'
import { getErrorMessage } from '@/app/lib/getErrorMessage'

import { Input } from '@/app/components/Input'
import { PrimaryButton } from '@/app/components/PrimaryButton'
import { OutlineButton } from '@/app/components/OutlineButton'
import { Headline4 } from '@/app/components/Typography'
import { ErrorMessage } from '@/app/components/ErrorMessage'
import { GoogleLogo } from '@/app/icons/GoogleLogo'
import { MicrosoftLogo } from '@/app/icons/MicrosoftLogo'
import { useRef, useState } from 'react'
import AlertModal from '@/app/components/AlertModal/AlertModal'
import { useRouter } from 'next/router'
import { useAuthLogin } from '@/app/hooks/auth/useAuthLogin'
import { API_URL, ROUTES } from '@/app/lib/constants'
import { isPathWithLocale } from '@/app/lib/i18n'
import { MydraDuoLogo } from '@/app/icons/MydraDuoLogo/MydraDuoLogo'
import Link from 'next/link'
import { isValidRedirect } from '@/app/lib/auth'

type FormValues = {
  email: string
}

export function LoginPage() {
  const router = useRouter()
  const t = useTranslations('Login')

  const formSchema = yup.object<FormValues>().shape({
    email: yup.string().email().required(t('validationRequired')),
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
  })

  const redirectTo = (router?.query?.redirectTo as string) ?? ROUTES.homepage

  const [serverResponse, setServerResponse] = useState<{
    message: string
    type: 'success' | 'error'
  }>({
    message: '',
    type: 'success',
  })
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const [isRedirectingToExternalLogin, setIsRedirectingToExternalLogin] =
    useState(false)
  const { mutateAsync } = useAuthLogin()

  const formRef = useRef<HTMLFormElement>(null)

  const handleOnSubmit = async (data) => {
    try {
      await mutateAsync({
        ...data,
        ...(isValidRedirect(redirectTo) && {
          redirectTo: isPathWithLocale(redirectTo)
            ? redirectTo.startsWith('/')
              ? redirectTo
              : `/${redirectTo}`
            : `/${router.locale}${redirectTo}`,
        }),
      })
      setServerResponse({
        message: t('emailSent'),
        type: 'success',
      })
    } catch (error) {
      reset(
        {},
        {
          keepValues: true,
          keepErrors: true,
        }
      )
      setServerResponse({
        message: t('serverError'),
        type: 'error',
      })
    } finally {
      if (!isAlertModalOpen) {
        setIsAlertModalOpen(true)
      }
    }
  }

  const getRedirectTo = (searchString) => {
    const parsed = new URLSearchParams(searchString)
    const redirectTo = parsed.get('redirectTo') || ''
    return (
      window.location.origin +
      (redirectTo.startsWith('/') ? redirectTo : '/' + redirectTo)
    )
  }

  const redirectToGoogleLogin = () => {
    setIsRedirectingToExternalLogin(true)

    if (typeof window === 'undefined') {
      return
    }

    const redirectUrl = `${API_URL}/auth/google?redirectUrl=${getRedirectTo(window.location.search)}`
    window.location.href = redirectUrl
  }

  const redirectToMicrosoftLogin = () => {
    setIsRedirectingToExternalLogin(true)

    if (typeof window === 'undefined') {
      return
    }

    const redirectUrl = `${API_URL}/auth/microsoft?redirectUrl=${getRedirectTo(window.location.search)}`
    window.location.href = redirectUrl
  }

  return (
    <>
      <Head>
        <style>
          {` .mydra-base-layout { min-height: 0; height: 100vh; } `}
        </style>
      </Head>
      <div className="relative flex flex-col h-full md:flex-row">
        <Link
          href={ROUTES.homepage}
          className="z-10 text-black md:text-white md:absolute"
        >
          <MydraDuoLogo className="h-8 mx-auto mt-12 md:mt-7 md:ml-9" />
        </Link>
        <div className="relative flex-col items-center justify-center hidden gap-8 p-8 text-center md:w-1/2 md:flex">
          <div className="absolute w-full h-full p-2">
            <video
              className="object-cover w-full h-full rounded-xl filter brightness-[75%]"
              src="/login-page-video.mp4"
              autoPlay
              muted
              loop
              playsInline
            />
          </div>
          <Headline4 className="z-10 max-w-prose !text-4xl font-title text-white">
            {t('make-your-own-path')}
          </Headline4>
        </div>
        <div className="flex justify-center flex-1 w-full mt-6 overflow-y-auto md:mt-0 md:w-1/2 md:bg-cover">
          <div className="flex flex-col w-full bg-white md:my-auto md:w-80 md:mx-auto">
            <form
              className="container md:px-0 md:py-12"
              onSubmit={handleSubmit(handleOnSubmit)}
              ref={formRef}
            >
              <div className="flex flex-col">
                <Headline4 className="font-semibold text-center font-sans md:!text-3xl">
                  {t('welcome')}
                </Headline4>
                <p className="text-[--text] text-center text-xs md:text-sm">
                  {t('login-into-mydra')}
                </p>
                <div className="flex flex-col gap-4 mt-6">
                  <Input
                    type="email"
                    id="email"
                    placeholder={t('emailPlaceholder')}
                    errorMessage={getErrorMessage('email', errors)}
                    {...register('email')}
                  />
                  <div className="flex flex-col gap-2 md:gap-4">
                    <PrimaryButton className="w-full" isLoading={isSubmitting}>
                      {t('continue')}
                    </PrimaryButton>
                    <p className="mt-1 text-xs md:text-sm">
                      {t.rich('dont-have-account-signup', {
                        Link: (children) => (
                          <Link
                            href={{
                              pathname: ROUTES.signup,
                              query: { redirectTo },
                            }}
                            className="font-semibold text-center text-mydra-black"
                          >
                            {children}
                          </Link>
                        ),
                      })}
                    </p>
                  </div>
                  <hr
                    className="my-5 text-xs uppercase md:mt-2 border-lines hr-text"
                    data-content={t('or')}
                  />
                  <OutlineButton
                    className="w-full !text-sm font-semibold"
                    onClick={(e) => {
                      e.preventDefault()
                      redirectToGoogleLogin()
                    }}
                    disabled={isRedirectingToExternalLogin}
                  >
                    <div className="flex items-center">
                      <GoogleLogo className="inline mr-auto" />{' '}
                      <span className="mx-auto">{t('continueWithGoogle')}</span>
                    </div>
                  </OutlineButton>
                  <OutlineButton
                    className="w-full !text-sm font-semibold"
                    onClick={(e) => {
                      e.preventDefault()
                      redirectToMicrosoftLogin()
                    }}
                    disabled={isRedirectingToExternalLogin}
                  >
                    <div className="flex items-center">
                      <MicrosoftLogo className="inline mr-auto" />{' '}
                      <span className="mx-auto">
                        {t('continueWithMicrosoft')}
                      </span>
                    </div>
                  </OutlineButton>
                </div>
                <ErrorMessage
                  message={errors.root?.serverError?.message}
                  className="mt-2 text-left"
                />
              </div>
            </form>
          </div>
        </div>
        <AlertModal
          message={serverResponse.message}
          isOpen={isAlertModalOpen}
          onClose={async (result) => {
            if (result) {
              formRef.current?.requestSubmit()
            }
            setIsAlertModalOpen(false)
          }}
        />
      </div>
    </>
  )
}
