import { LoginPage } from '@/app/modules/authentication/pages/Login'

import '@/app/globals.css'

export async function getStaticProps(context) {
  return {
    props: {
      messages: (await import(`@/locales/${context.locale}/strings.json`))
        .default,
      locale: context.locale,
    },
  }
}

export default LoginPage
