import AuthService, { IPasswordLessAuthLoginPayload } from '@/app/services/auth'
import { ErrorResponse, IApiIndividualResponse } from '@/types/api'
import { ISession } from '@/types/session'
import { useMutation } from 'react-query'

export function useAuthLogin() {
  return useMutation<
    IApiIndividualResponse<ISession>,
    ErrorResponse,
    IPasswordLessAuthLoginPayload
  >(AuthService.POST_PATH_PASSWORD_LESS_LOGIN, AuthService.passwordLessLogin)
}
